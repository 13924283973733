import React, { useEffect, useState } from "react";
import { Select, Checkbox, TextArea, Button } from "./elements";
import { ValidateEmail } from "../utils/format";
import { useDispatch, useSelector } from "react-redux";
import { elementAction, feedbackAction, uwpAction } from "../store";
import Uwp, { UploadFileStatusEnum } from "../utils/uwp";
import Api, { CreateAC } from "../api";
import Log from "../utils/log";
import { Trans } from "react-i18next";
import TitleIcon from "./elements/TitleIcon";
import ArmouryCrateReportForm from "./ArmouryCrateProblemForm";

export default function DislikeForm(props) {
  const { t } = props;
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.element.theme);
  const [dislikeCategory, setDislikeCategory] = useState("");

  const feedbackOptions = [
    {
      value: "#dislike_1",
    },
    {
      value: "#dislike_2",
    },
  ];

  async function linkToContactAsus() {
    await Uwp.redirectToAsusSupport();
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
      className={"relative"}
    >
      <div className={"mt-12 text-white flex items-baseline space-x-3"}>
        <TitleIcon width={40} height={15} />
        <p className={`title text-${theme}-normal`}>
          {t("dislike_form_title")}
        </p>
      </div>
      <div className={"mt-5"}>
        <Select
          t={t}
          name={"first_category"}
          options_i18n={"dislike_options"}
          value={dislikeCategory}
          placeHolder={t("please_select_one_category")}
          options={feedbackOptions}
          changeValue={(val) => {
            setDislikeCategory(val);
          }}
        />
      </div>
      <div className={dislikeCategory === "#dislike_1" ? "block" : "hidden"}>
        <ArmouryCrateReportForm t={t} />
      </div>
      <div className={dislikeCategory === "#dislike_2" ? "block" : "hidden"}>
        <div className="mt-5 flex w-full justify-between space-x-2">
          <p
            className={`text-xl flex justify-start text-${theme}-normal break-words xl:whitespace-normal max-w-1/2`}
          >
            {t("contact_asus_message")}
            {/* <Trans i18nKey="subTitle">
              <>
                className="underline whitespace-nowrap" href=
                {t("acse_faq_link")}
                target={"new"}
              </>
              <>
                className="underline whitespace-nowrap" href=
                {t("asus_service_center_url")}
                target={"new"}
              </>
            </Trans> */}
          </p>
          <div className="group flex xl:justify-end items-center justify-center">
            <Button
              id="contact_asus"
              theme={theme}
              onClick={linkToContactAsus}
              isFocus={(name) => false}
              isEnabled={true}
            >
              {t("contact_asus")}
            </Button>
          </div>
        </div>
      </div>
      {/*{dislikeCategory === '#dislike_2' && ()}*/}
    </form>
  );
}
