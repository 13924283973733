export default function Button ({
  className,
  id,
  children,
  theme,
  isEnabled,
  isFocus,
  onClick,
}) {
  const css = () => {
    // return isSelectedItem(name) ? ` border-${theme}-on-focus ` : `  `
    return isFocus(id)
      ? ` border-${theme}-on-focus border-[5px] opacity-80 placeholder-${theme}-focus bg-${theme}-btn-focus `
      : ` bg-${theme}-btn-disabled border-[0px]  placeholder-${theme}-normal hover:border-[3px]`
  }

  return (
    <>
      <button
        id={id}
        disabled={!isEnabled}
        type="button"
        onClick={(e) => onClick(e)}
        className={`h-[40px] w-[250px] button-text px-4 ${className}
            ${
          isEnabled
            ? css() +
            ` text-${theme}-normal group-hover:bg-${theme}-btn-hover group-hover:border-${theme}-btn-hover group-active:bg-${theme}-btn-click group-active:border-${theme}-btn-click `
            : ` text-${theme}-disabled bg-${theme}-btn-disabled`
        }
             font-semibold relative`}
      >
        {children}
        <div
          className={`absolute w-2 h-2 left-[0px] top-[0px]
               ${isEnabled
            ? 'group-hover:hidden group-active:border-btn-click-light'
            : 'border-gray-500'}
               border-t-[1px] border-l-[1px]`}/>
        <div
          className={`absolute w-2 h-2 right-[0px] top-[0px]
               ${isEnabled
            ? 'group-hover:hidden group-active:border-btn-click-light'
            : 'border-gray-500'}
               border-t-[1px] border-r-[1px]`}/>
        <div
          className={`absolute w-2 h-2 left-[0px] bottom-[0px]
               ${isEnabled
            ? 'group-hover:hidden group-active:border-btn-click-light'
            : 'border-gray-500'}
               border-b-[1px] border-l-[1px] `}/>
        <div
          className={`absolute w-2 h-2 right-[0px] bottom-[0px]
               ${isEnabled
            ? 'group-hover:hidden group-active:border-btn-click-light'
            : 'border-gray-500'}
               border-b-[1px] border-r-[1px]`}/>
      </button>
    </>
  )
}
