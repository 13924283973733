import React, { useEffect, useState } from 'react'

export default function Keyboard({ options, keyDownCheck, arrowUp, keyEnter = () => {}, keyEsc, arrowDown, arrowRight, arrowLeft, handleClick, selected }) {
    const [keyPressed, setKeyPressed] = useState({});

    useEffect(() => {
        const handleKeyDown = (event) => {
            // 如果該按鍵尚未被按下
            if (!keyPressed[event.code]) {
                const updatedKeys = { ...keyPressed, [event.code]: true };
                setKeyPressed(updatedKeys);
                if (keyDownCheck) {
                    if (keyDownCheck(event.code)) return
                }
                const code = event.code;
                blur()
                switch (code) {
                    case 'ArrowUp':
                        if (arrowUp) arrowUp()
                        break;
                    case 'ArrowDown':
                        if (arrowDown) arrowDown()
                        break;
                    case 'ArrowLeft':
                        if (arrowLeft) arrowLeft()
                        break
                    case 'ArrowRight':
                        if (arrowRight) arrowRight()
                        break
                    case 'Enter':
                        if (keyEnter) keyEnter()
                        break;
                    case 'Escape':
                        if (keyEsc) keyEsc()
                        break;
                }
            }
        };

        const blur = () => {
            try {
                const element = document.getElementById(options[selected]);
                element.blur();
            } catch (e) { }
        }

        const handleKeyUp = (event) => {
            const updatedKeys = { ...keyPressed };
            delete updatedKeys[event.code];
            setKeyPressed(updatedKeys);
        };

        // 添加事件監聽器
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        if (handleClick) window.addEventListener('click', handleClick)

        // 清理工作：組件卸載時移除事件監聽器
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            if (handleClick) window.removeEventListener('click', handleClick);
        };
    }, [keyPressed, options]); // focusNameList 也應該添加到依賴列表
    return (<></>);
}
