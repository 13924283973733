import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Uwp, { UploadFileStatusEnum } from "./utils/uwp";
import Log from "./utils/log";
import { elementAction, feedbackAction } from "./store";
import { AppClient } from "./utils/tool/client";
import { Trans, withTranslation } from "react-i18next";
import { PrivacyNotificationDialog, SubmitDialog } from "./components/dialog";
import LikeForm from "./components/LikeForm";
import RadioButton from "./components/elements/RadioButton";
import TitleIcon from "./components/elements/TitleIcon";
import DislikeForm from "./components/DislikeForm";

function App({ t, i18n }) {
  const dispatch = useDispatch();
  const [selectedLikeType, setSelectedLikeType] = useState("");
  const privacyNotificationDialogId = useSelector(
    (state) => state.element.privacyNotificationDialogId
  );
  const submitDialogId = useSelector((state) => state.element.submitDialogId);
  const errorDialogId = useSelector((state) => state.element.errorDialogId);
  const lang = useSelector((state) => state.element.lang);
  const isShowErrorDialog = useSelector(
    (state) => state.element.isShowErrorDialog
  );
  const isShowPrivacyNotificationDialog = useSelector(
    (state) => state.element.isShowPrivacyNotificationDialog
  );
  const isShowSubmitDialog = useSelector(
    (state) => state.element.isShowSubmitDialog
  );
  const attachmentFileUploadDone = useSelector(
    (state) => state.feedback.attachmentFileUploadDone
  );
  const uploadStatus = useSelector((state) => state.uwp.uploadStatus);
  const uploadProgress = useSelector((state) => state.uwp.uploadProgress);
  const [isSetLanguage, setIsSetLanguage] = React.useState(false);
  const focusNameList = useSelector((state) => state.element.focusNameList);
  const theme = useSelector((state) => state.element.theme);

  const getTheme = async () => {
    if (Uwp.getBridge()) {
      Log.debug("[UWP] start get theme ");
      const themeType = await Uwp.getThemeType();
      if (themeType) {
        const t = themeType.toLowerCase();
        Log.debug("[UWP] start get theme ", t);
        dispatch(elementAction.setTheme(t));
      }
    } else {
      Log.debug("[UWP] no UWP, skip get theme ");
    }
  };

  const getCurrentLanguage = async () => {
    let lang = navigator.language || navigator.userLanguage;
    if (Uwp.getBridge()) {
      lang = await Uwp.getCurrentLanguage();
      Log.info("use UWP language:", lang);
    } else {
      Log.info("use browser language:", lang);
    }
    setTimeout(() => {
      setIsSetLanguage(true);
      dispatch(elementAction.setLang(lang));
    }, 1000);
  };
  const getCountryCode = async () => {
    const countryCode = await AppClient.getCountry();
    dispatch(feedbackAction.setGeoCountry(countryCode));
  };

  const getUserInfo = async () => {
    if (Uwp.getBridge()) {
      Log.debug("[UWP] start get user info ");
      const userInfo = await Uwp.getLoginUserInfo();
      if (userInfo) {
        Log.debug("[UWP] start get user info ", userInfo);
        dispatch(feedbackAction.setEmail(userInfo.Email));
      }
    } else {
      Log.debug("[UWP] no UWP, skip get user info ");
    }
  };

  useEffect(() => {
    getTheme();
    getUserInfo();
    getCurrentLanguage();
    getCountryCode();
  }, []);

  useEffect(() => {
    Log.debug("[UWP] change upload status ", uploadStatus);
    if (uploadStatus === UploadFileStatusEnum.UploadFileFinish) {
      dispatch(feedbackAction.submitSuccess());
    }
    if (uploadStatus > UploadFileStatusEnum.PackageEnd) {
      if (uploadStatus % 100 === 0 || uploadStatus % 100 === 2) {
        dispatch(
          feedbackAction.setLogUploadProgress(80 + Number(uploadProgress) * 0.2)
        );
      }
      if (Number(uploadStatus) % 100 === 4) {
        Log.error("[UWP] upload fail, dispatch submitFailed", uploadStatus);
        dispatch(feedbackAction.submitFailed());
      }
    }
  }, [uploadStatus]);

  useEffect(() => {
    if (selectedLikeType === "") {
      Uwp.clearWebContent();
    } else {
      Uwp.changeWebContent();
    }
  }, [selectedLikeType]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    triggerDialog(errorDialogId, isShowErrorDialog);
  }, [errorDialogId, isShowErrorDialog]);

  useEffect(() => {
    triggerDialog(privacyNotificationDialogId, isShowPrivacyNotificationDialog);
    dispatch(
      elementAction.setEnableRootKeyboardEvent(!isShowPrivacyNotificationDialog)
    );
  }, [privacyNotificationDialogId, isShowPrivacyNotificationDialog]);

  useEffect(() => {
    triggerDialog(submitDialogId, isShowSubmitDialog);
    dispatch(elementAction.setEnableRootKeyboardEvent(!isShowSubmitDialog));
  }, [submitDialogId, isShowSubmitDialog]);

  useEffect(() => {
    if (attachmentFileUploadDone) {
      dispatch(feedbackAction.submitSuccess());
    }
  }, [attachmentFileUploadDone]);

  function triggerDialog(name, action) {
    const target = document.getElementById(name);
    if (target) {
      action ? target.showModal() : target.close();
    }
  }
  return (
    <div className="h-screen overflow-auto">
      {/*<KeyboardComponent />*/}
      {!isSetLanguage && (
        <div
          className={`font-sans fixed bg-${theme} top-0 bottom-0 left-0 right-0 flex justify-center items-center z-50`}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/loading.gif`}
            alt="logo"
            className="w-[85px]"
          />
        </div>
      )}
      <div className={"p-5"}>
        <div className={"flex items-baseline space-x-3"}>
          <TitleIcon width={40} height={15} />
          <p className={`title text-${theme}-normal`}>{t("init_title")}</p>
        </div>

        {/*<div className="pt-[8px]">*/}
        {/*  <p className={`subtitle text-${theme}-normal break-all`}>*/}
        {/*    <Trans i18nKey="subTitle">*/}
        {/*      <a*/}
        {/*        className="underline"*/}
        {/*        href={t("acse_faq_link")}*/}
        {/*        target={"new"}*/}
        {/*      />*/}
        {/*      <a*/}
        {/*        className="underline"*/}
        {/*        href={t("asus_service_center_url")}*/}
        {/*        target={"new"}*/}
        {/*      />*/}
        {/*    </Trans>*/}
        {/*  </p>*/}
        {/*</div>*/}
        <div className={"flex flex-row my-4"}>
          <RadioButton
            id={"like"}
            label={t("like_label")}
            className={"flex basis-1/6 justify-start items-center w-full"}
            theme={theme}
            isFocus={false}
            isEnabled={true}
            isChecked={selectedLikeType === "like"}
            onClick={() => {
              setSelectedLikeType("like");
            }}
          />
          <RadioButton
            id={"dislike"}
            label={t("dislike_label")}
            className={"flex justify-start items-center w-full"}
            theme={theme}
            isFocus={false}
            isEnabled={true}
            isChecked={selectedLikeType === "dislike"}
            onClick={() => {
              setSelectedLikeType("dislike");
            }}
          />
        </div>
        <div className={selectedLikeType === "like" ? "block" : "hidden"}>
          <LikeForm t={t} />
        </div>
        <div className={selectedLikeType === "dislike" ? "block" : "hidden"}>
          <DislikeForm t={t} />
        </div>
      </div>
      <PrivacyNotificationDialog t={t} id={privacyNotificationDialogId} />
      <SubmitDialog t={t} id={submitDialogId} />
    </div>
  );
}

export default withTranslation()(App);
