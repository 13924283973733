import { createSlice } from '@reduxjs/toolkit'
import { UploadFileStatusEnum } from '../utils/uwp'
import Log from '../utils/log'
export const uwp = createSlice({
  name: 'uwp',
  initialState: {
    uploadStatus: UploadFileStatusEnum.Normal,
    uploadProgress: 0,
    uploadMsg: '',
    enableSync: false,
  },
  reducers: {
    updateUploadStatus: (state, action) => {
      const { status, percentage, msg } = action.payload
      state.uploadStatus = status
      state.uploadProgress = percentage
      state.uploadMsg = msg

      switch (status) {
        case UploadFileStatusEnum.PackageStart:
        case UploadFileStatusEnum.GetUploadLinkStart:
        case UploadFileStatusEnum.UploadFileStart:
        case UploadFileStatusEnum.Canceling:
          state.enableSync = true
          break
        default:
          state.enableSync = false
      }
    },
    enableSync: state => {
      state.enableSync = true
    },
    disableSync: state => {
      state.enableSync = false
    },
  }
})

export default uwp.reducer
