import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function Input ({
  t,
  type='text',
  placeHolder,
  errorText,
  id,
  value, onChange, formatCheck = () => {} }) {

  const [showError, setShowError] = useState(false)
  const focusIndex = useSelector(state => state.element.focusIndex)
  const isFocus = useSelector(state => state.element.isFocus)
  const [isEdit, setIsEdit] = useState(false)
  const focusNameList = useSelector(state => state.element.focusNameList)
  const theme = useSelector(state => state.element.theme)

  useEffect(() => {
    document.getElementById(id).addEventListener('focusout', _check, true)
    return () => {
      document.getElementById(id)
        .removeEventListener('focusout', _check, true)
    }
  })

  const selectedItem = () => {
    const idx = focusNameList.findIndex((item) => {
      return item === id
    })
    if ((idx === focusIndex) && isFocus) {
      let css = ` border-[3px] opacity-80 placeholder-${theme}-focus bg-opacity-50 `
      if (isEdit) {
        return ` ${css} border-red-500 bg-red-800 `
      } else {
        return ` ${css} border-${theme}-on-focus bg-${theme}-input-focus`
      }
    }
    if (isEdit) {
      setIsEdit(false)
    }
    return `  border-[1px] border-${theme}-normal bg-${theme}-input placeholder-${theme}-normal`
  }

  const _check = () => {
    setShowError(!formatCheck())
  }

  function _onChange(e) {
    setIsEdit(true)
    onChange(e)
  }

  return (
    <>
      <div className="flex flex-col h-[52px] mt-5 relative">
        <div className="inset-0" aria-hidden={'true'}>
          <div
            className="absolute w-2 h-2 left-0 top-0 border-t-[1.5px] border-l-[1.5px]"></div>
          <div
            className="absolute w-2 h-2 left-0 bottom-0 border-b-[1.5px] border-l-[1.5px]"></div>
          <div
            className="absolute w-2 h-2 right-0 bottom-0 border-b-[1.5px] border-r-[1.5px]"/>
        </div>
        <input
          className={`h-full w-full text-xl px-4 text-[22px] input-text text-${theme}-normal 
          ${showError && 'bg-btn-click '} ${selectedItem()}
          `}
          id={id}
          name={id}
          type={type}
          placeholder={placeHolder}
          aria-label={placeHolder}
          aria-roledescription={'input'}
          value={value}
          autoComplete="off"
          onChange={(e) => _onChange(e.target.value)}
          spellCheck="false"
          aria-autocomplete="none"
        />
      </div>
      {showError && (
        <div className={'flex w-full h-[24px] grow-0 mt-2'}>
          <div className={'w-[24px] h-full z-10 grid place-items-center'}>
            <div className={'bg-error bg-contain bg-no-repeat w-full h-full'}/>
          </div>
          <p
            className={'h-full align-middle grid place-items-center px-2 text-error font-sans'}>
            {errorText}
          </p>
        </div>
      )}
    </>
  )

}
