export default function RadioButton ({
  theme,
  id,
  className,
  label,
  isFocus,
  isEnabled,
  isChecked,
  onClick = () => {},
}) {
  const radio = () => {
    let css = 'border-[1.5px] rounded-full flex items-center justify-center '
    const tick = theme === 'light' ? 'bg-white-tick' : 'bg-dark-tick'
    // if (isChecked) {
    //   css += ` bg-white p-3`
    //   // css += ` ${tick} bg-cover bg-${theme}-checked `;
    // }
    // if (isFocus) {
    //   css += ` rounded-full  border-[3px]  ${isChecked} `;
    //   if (isChecked) {
    //     css += ` ${tick} bg-cover bg-${theme}-input-focus  bg-opacity-80`;
    //   }
    // } else {
    //   const borderColor = isEnabled
    //     ? ` border-${theme}-checkbox-enabled `
    //     : ` border-${theme}-checkbox-disabled `;
    //   const bgColor = isEnabled
    //     ? ` bg-${theme}-checkbox-enabled `
    //     : ` bg-${theme}-checkbox-disabled `;
    //   css += ` ${
    //     theme !== "light" && "border-[1px]"
    //   } ${borderColor} ${bgColor} `;
    //   if (isChecked) {
    //     css += ` ${tick} bg-cover bg-${theme}-checked `;
    //   } else {
    //     css += ` border-[2px] `;
    //   }
    // }
    return css
  }

  return (
    <>
      <div className={className}>
        <div
          id={id}
          aria-hidden={'true'}
          aria-label={label}
          aria-roledescription={'checkbox'}
          className={`w-7 h-7 mr-3  
            ${radio()}`}
          onClick={onClick}
        >
          {isChecked && <div
            className={`w-5 h-5 items-center bg-white rounded-full`}></div>}
        </div>
        <label
          className={`text ${
            isEnabled ? `text-${theme}-normal` : `text-${theme}-disabled`
          }`}
          form="scales"
          aria-label={label}
          aria-roledescription={'input'}
          aria-hidden={'true'}
          onClick={onClick}
        >
          {label}
        </label>
      </div>
    </>
  )
}
