import { useSelector } from "react-redux";
import LikeSuccessIcon from "./components/elements/icon/LikeSuccess";
import { Button } from "./components/elements";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Uwp from "./utils/uwp";

export function App({ t, i18n }) {
  const theme = useSelector((state) => state.element.theme);

  async function confirm() {
    await Uwp.clearWebContent();
    Uwp.redirectToSettingsPage();
    window.location.href = "/ac-feedback-hub";
  }

  return (
    <div
      className={`w-full flex flex-col min-h-screen text-${theme}-normal text-2xl flex mt-[300px] space-y-3`}
    >
      <LikeSuccessIcon className={"w-full flex justify-center"} />
      <p className={"w-full flex justify-center"}>
        {t("like_confirm_message")}
      </p>
      <div className={"w-full flex justify-center pt-7"}>
        <Button
          className={"h-[50px] w-[230px]"}
          id="contact_asus"
          theme={theme}
          onClick={confirm}
          isFocus={(name) => false}
          isEnabled={true}
        >
          {t("confirm")}
        </Button>
      </div>
    </div>
  );
}

export default withTranslation()(App);
