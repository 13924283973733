export default function TextArea({
  t,
  className,
  id,
  name,
  isFocus,
  theme,
  onChange,
  form,
}) {
  function getDescriptionWordCount() {
    return form.description.length;
  }

  function css() {
    return isFocus(name)
      ? ` border-${theme}-on-focus bg-${theme}-input-focus border-[3px] opacity-80 placeholder-${theme}-focus bg-opacity-50 `
      : ` bg-${theme}-input border-[1px] placeholder-${theme}-normal  border-[1px] border-${theme}-normal`;
  }

  return (
    <>
      <div className={className}>
        <div
          className={`absolute w-2 h-2 left-[-1px] top-[-1px]
            group-hover:border-btn-hover-light group-active:border-btn-click-light
               border-t-[1.5px] border-l-[1.5px]`}
        />
        <div
          className={`absolute w-2 h-2 left-[-1px] bottom-[-1px]
                           group-hover:border-btn-hover-light group-active:border-btn-click-light
               border-b-[1.5px] border-l-[1.5px] `}
        />
        <div
          className={`absolute w-2 h-2 right-[-1px] bottom-[-1px]
                         group-hover:border-btn-hover-light group-active:border-btn-click-light
               border-b-[1.5px] border-r-[1.5px]`}
        />
        <textarea
          className={
            `h-full w-full text-xl px-4 text-${theme}-normal  pt-2 input-text ` +
            css()
          }
          maxLength="1000"
          placeholder={t("description", {
            armoury_crate: t("armoury_crate"),
          })}
          id={id}
          name={name}
          value={form.description}
          onChange={onChange}
        />
        <div
          className="absolute right-3 bottom-2 text-gray-500 text-xl"
          aria-hidden={"true"}
        >
          {getDescriptionWordCount()}/1000
        </div>
      </div>
    </>
  );
}
