export function ValidateEmail (input) {
  const validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return validRegex.test(input)
}

export function Enum(obj) {
  return Object.freeze(obj);
}

export function isEEACountry(countryCode) {
  const eeaCountries = [
    "AT", // Austria
    "BE", // Belgium
    "BG", // Bulgaria
    "HR", // Croatia
    "CY", // Cyprus
    "CZ", // Czech Republic
    "DK", // Denmark
    "EE", // Estonia
    "FI", // Finland
    "FR", // France
    "DE", // Germany
    "GR", // Greece
    "HU", // Hungary
    "IE", // Ireland
    "IT", // Italy
    "LV", // Latvia
    "LT", // Lithuania
    "LU", // Luxembourg
    "MT", // Malta
    "NL", // Netherlands
    "PL", // Poland
    "PT", // Portugal
    "RO", // Romania
    "SK", // Slovakia
    "SI", // Slovenia
    "ES", // Spain
    "SE", // Sweden
    "IS", // Iceland
    "LI", // Liechtenstein
    "NO",  // Norway

    "GB", // United Kingdom
    "CH", // Switzerland
    "UA", // Ukraine
  ];

  return eeaCountries.includes(countryCode.toUpperCase());
}

export function checkPrivacyPolicyAbortAge(countryCode) {
  let age = 0
  switch (countryCode.toUpperCase()) {
    case "TH": // Thailand
      age = 20
      break
    case "BR": // Brazil
    case "ZA": // South Africa
      age = 18
      break
    case "CN": // China
    case "KR": // South Koreaka
      age = 14
      break
    default:
      if (isEEACountry(countryCode)) {
        age = 16
      }
  }
  return age
}

export function formatDateToUTC(date) {
  const pad = (number) => (number < 10 ? '0' + number : number);

  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1);  // 0-indexed
  const day = pad(date.getUTCDate());
  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getUTCMinutes());
  const seconds = pad(date.getUTCSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
