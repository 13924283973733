import axios from 'axios'
import Log from '../utils/log'

export default class IpGeo {
  constructor (...urls) {
    this.urls = urls
  }

  async get () {
    for (const url of this.urls) {
      try {
        const resp = await fetch(url,{
          headers: {
            'Custom-Agent': 'ROGFeedbackHub',
          }
        });
        return await resp.json()
      } catch (e) {
        Log.error('get country code error', e)
      }
    }
    return null
  }
}
