import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "./index";
import Uwp from "../../utils/uwp";
import { feedbackAction, elementAction } from "../../store";

export default function Uploader({ t, className }) {
  const theme = useSelector((state) => state.element.theme);
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [fileSize, setFileSize] = useState("0");
  const dispatch = useDispatch();
  const acceptFile = ["jpg", "png", "gif"].join(", ");
  const maxFiles = 5;
  useEffect(() => {
    checkFiles();
    _changeFile();
  }, [files]);

  function checkFiles() {
    const _maxSize = 10;
    const maxSize = _maxSize * 1024 * 1024; // 10MB

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    let error = "";

    if (files.length > maxFiles) {
      error += `最多只能上傳 ${maxFiles} 個檔案。\n`;
    }
    setFileSize("0");
    let size = 0;
    for (let i = 0; i < files.length; i++) {
      if (!allowedTypes.includes(files[i].type)) {
        error += `檔案 ${files[i].name} 格式不正確。\n`;
      }
      size += files[i].size;
      if (files[i].size > maxSize) {
        error += `檔案 ${files[i].name} 超過 ${_maxSize}MB 的限制。\n`;
      }
    }
    if (error) {
      setFiles([]);
      dispatch(elementAction.triggerSubmitDialog(true));
      dispatch(feedbackAction.uploadFileError());
      console.log(error);
      return;
    }
    if (size > 0) {
      size = (size / 1024 / 1024).toFixed(2);
      setFileSize(size);
    }
  }

  function handleFileChange(event) {
    const _files = event.target.files;
    const fileArray = Array.from(_files).map((file) => file);
    setFiles(fileArray);
  }

  async function _changeFile() {
    await Uwp.updateScreenshot(files);
  }

  function handleUpload() {
    fileInputRef.current.click();
  }

  function removeFile(file) {
    const f = files.filter((f) => f !== file);
    setFiles((prev) => f);
  }

  return (
    <>
      <div className={`text-${theme}-normal flex space-x-5 items-center`}>
        <p className={`text-${theme}-normal`}>{t("upload_screen_shot")}</p>
        <p className={`mt-2 text-sm text-${theme}-disabled`}>
          {t("file_format")}: {acceptFile} / {t("file_size")}: {fileSize}MB
        </p>
      </div>
      <div className="flex flex-col mt-5 relative min-h-[40px]">
        <input
          type={"file"}
          ref={fileInputRef}
          className={"hidden"}
          onChange={handleFileChange}
          multiple
          accept={acceptFile}
        />
        <div className="inset-0" aria-hidden={"true"}>
          <div className="absolute w-2 h-2 left-0 top-0 border-t-[1.5px] border-l-[1.5px]"></div>
          <div className="absolute w-2 h-2 left-0 bottom-0 border-b-[1.5px] border-l-[1.5px]"></div>
          <div className="absolute w-2 h-2 right-0 bottom-0 border-b-[1.5px] border-r-[1.5px]" />
        </div>
        <div
          className={`min-h-[50px] h-full w-full text-xl text-[22px] pl-[5px] input-text text-${theme}-normal flex flex-row ${className} border-[1px] border-${theme}-normal flex-wrap`}
        >
          {files.map((file, index) => (
            <SingleFile
              key={index}
              theme={theme}
              file={file}
              onRemove={removeFile}
            />
          ))}
        </div>
      </div>
      <div className={"flex justify-between pt-2"}>
        <Button
          id="add_files"
          className={`h-[40px] ${className}`}
          theme={theme}
          onClick={handleUpload}
          isFocus={(name) => false}
          isEnabled={true}
        >
          {t("add_files")}
        </Button>
        <p className={`text-${theme}-disabled text-sm mt-2`}>
          {t("max_attachment_number")}: {files.length} / {maxFiles}
        </p>
      </div>
    </>
  );
}

const SingleFile = ({ className, theme, file, onRemove }) => {
  function _remove() {
    onRemove(file);
  }

  async function previewFile() {
    await Uwp.previewScreenshot(file);
  }

  return (
    <div
      className={`flex space-x-3 min-w-[120px] min-h-[32px] px-5 my-2 mx-[5px] align-middle text-center justify-center items-center text-sm ${className} border-[1px] border-${theme}-normal`}
    >
      <span
        className="h-full flex items-center justify-center"
        onClick={previewFile}
      >
        {file.name}
      </span>
      <svg
        onClick={_remove}
        className={"z-[999]"}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.646667 1.35359L1.35377 0.646484L8.00058 7.29329L14.6474 0.646487L15.3545 1.35359L8.70769 8.0004L15.3545 14.6472L14.6474 15.3543L8.00058 8.7075L1.35377 15.3543L0.646667 14.6472L7.29347 8.0004L0.646667 1.35359Z"
          fill="#F2F2F2"
        />
      </svg>
    </div>
  );
};
