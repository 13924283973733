import React, { useEffect, useState } from 'react'
import './App.css'
import i18n from 'i18next'
import { initReactI18next, Trans, withTranslation } from 'react-i18next'
import { PrivacyNotificationDialog, SubmitDialog } from './components/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { elementAction, feedbackAction } from './store'
import Backend from 'i18next-http-backend'
import Uwp, { UploadFileStatusEnum } from './utils/uwp'
import Log from './utils/log'
import { AppClient } from './utils/tool/client'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Home from './Home'
import LikeConfirm from './LikeConfirm'

let lng = 'en'

i18n
  .use(Backend)
  // .use(LanguageDetector) //偵測瀏覽器語言
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    backend: {
      loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json',
    },
    lng, // disabled detection
    fallbackLng: {
      default: ['en'],
      'zh-hant': ['zh-TW'],
      'zh-hans': ['zh-CN'],
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'],
      wait: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
  .then((r) => {})

function App ({ t, i18n }) {
  return (
    <Router>
      <Routes>
        <Route path="/ac-feedback-hub" element={<Home />}/>
        <Route path="/ac-feedback-hub/like-confirm" element={<LikeConfirm />}/>

      </Routes>
    </Router>
  )
}

export default withTranslation()(App)
