const LogLevels = ["debug", "info", "warning", "error"];

class options {
  level = "debug";
  background = "gray";
  color = "white";
  fontSize = "normal";

  constructor({ level, background, color, fontSize }) {
    this.level = level;
    this.background = background;
    this.color = color;
    this.fontSize = fontSize;
  }
}

class Log {
  static print(options, ...message) {
    console.log(
      "%c[" + options.level + "] " + message.join(" "),
      "background: " +
        options.background +
        "; color: " +
        options.color +
        "; font-size: " +
        options.fontSize
    );
  }

  static info(...message) {
    Log.print(
      new options({ level: "info", background: "green", color: "white" }),
      ...message
    );
  }

  static debug(...message) {
    Log.print(
      new options({ level: "debug", background: "blue", color: "white" }),
      ...message
    );
  }

  static warning(...message) {
    Log.print(
      new options({ level: "warning", background: "yellow", color: "black" }),
      ...message
    );
  }

  static error(...message) {
    Log.print(
      new options({ level: "error", background: "red", color: "white" }),
      ...message
    );
  }
}

export default Log;
