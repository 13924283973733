import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { elementAction } from "../../store";
import { useSelector } from "react-redux";
import { Keyboard } from "../control";

export default function Select({
  t,
  className,
  name,
  placeHolder,
  options,
  value,
  changeValue,
  options_i18n = "category_options",
}) {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const focusIndex = useSelector((state) => state.element.focusIndex);
  const focusNameList = useSelector((state) => state.element.focusNameList);
  const [showOptions, setShowOptions] = useState(false);
  const [selected, setSelected] = useState(0);
  const categoryI18n = t(options_i18n, { returnObjects: true });
  const targetRef = useRef(null);
  const optionRef = useRef(null);
  const isFocus = useSelector((state) => state.element.isFocus);
  const theme = useSelector((state) => state.element.theme);

  const selectedItem = () => {
    const idx = focusNameList.findIndex((item) => {
      return item === name;
    });
    return idx === focusIndex && isFocus
      ? ` border-${theme}-on-focus bg-${theme}-input-focus border-[3px] opacity-80 placeholder-${theme}-focus bg-opacity-50 `
      : ` border-${theme}-normal placeholder-${theme}-normal `;
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    setSelected(0);
  }, [options]);

  function handleClickOutside(event) {
    if (
      targetRef.current &&
      !targetRef.current.contains(event.target) &&
      optionRef.current &&
      !optionRef.current.contains(event.target)
    ) {
      setShowOptions(false);
    }
  }
  function label() {
    return categoryI18n[value] || "";
  }
  function triggerOptions() {
    setShowOptions(!showOptions);
  }

  function _changeValue(val) {
    //   if (typeof (options[0]) === 'object') {
    //     const { subOptions } = options[selected]
    //     if (subOptions.length > 1) {
    //       dispatch(elementAction.setSecondCategoryFocusNameList(options[selected].subOptions))
    //     }
    //     // dispatch(elementAction.setFocusIndex(2))
    //   } else {
    //     // dispatch(elementAction.setFocusIndex(3))
    //   }
    changeValue(val);
    setShowOptions(false);
  }

  const getItem = (data) => {
    return data.value ? data.value : data;
  };

  useEffect(() => {
    dispatch(elementAction.setEnableRootKeyboardEvent(!showOptions));
  }, [showOptions]);

  const checkScroll = () => {
    if (selectRef.current) {
      const { clientHeight } = selectRef.current;
      const optionsHeight =
        document.getElementById(getItem(options[selected])).clientHeight *
        (selected + 1);
      if (selected === 0) {
        selectRef.current.scrollTop = 0;
      } else if (optionsHeight > clientHeight) {
        selectRef.current.scrollTop = optionsHeight - clientHeight;
      }
    }
  };

  useEffect(() => {
    checkScroll();
  }, [selected]);

  const arrowUp = () => {
    if (selected === 0) {
      setSelected(options.length - 1);
    } else {
      setSelected(selected - 1);
    }
    if (selected === 0) {
      selectRef.current.scrollTop = 0;
    }
  };

  const arrowDown = () => {
    checkScroll();
    if (selected === options.length - 1) {
      setSelected(0);
    } else {
      setSelected(selected + 1);
    }
    if (selected === 0) {
      selectRef.current.scrollTop = 0;
    }
  };

  const keyEnter = () => {
    _changeValue(getItem(options[selected]));
  };

  const keyEsc = () => {
    setShowOptions(false);
  };

  const selectCss = (item, index) => {
    const mockHoverAct = selected === index;
    // if (value === getItem(item)) {
    //   if (mockHoverAct) {
    //     return ' bg-blue-600 '
    //   }
    //   return `bg-gray-800 hover:bg-blue-600 hover:text-black `
    // }
    return mockHoverAct
      ? ` bg-${theme}-selected `
      : value === getItem(item)
      ? `bg-${theme}-option-selected hover:bg-${theme}-selected hover:text-${theme}-normal `
      : ` hover:bg-${theme}-selected `;
  };

  return (
    <div
      className={`${className} flex flex-col h-[40px] relative xl:w-1/3 md:w-2/3`}
    >
      <div className="inset-0" aria-hidden={"true"}>
        <div className="absolute w-2 h-2 left-0 top-0 border-t-[1.5px] border-l-[1.5px]"></div>
        <div className="absolute w-2 h-2 left-0 top-8 border-b-[1.5px] border-l-[1.5px]"></div>
        <div className="absolute right-0 top-8 w-2 h-2 border-b-[1.5px] border-r-[1.5px]" />
      </div>
      <input
        disabled
        aria-hidden={"true"}
        className={
          `h-full w-full px-4 border-[1px]	input-text bg-${theme}-input text-${theme}-normal ` +
          (showOptions ? `border-[3px] border-${theme}-normal ` : " ") +
          selectedItem()
        }
        placeholder={placeHolder}
        aria-label={placeHolder}
        aria-roledescription={"select"}
        autoComplete="off"
        value={label()}
      />
      <div
        ref={targetRef}
        id={name}
        aria-label={placeHolder}
        aria-roledescription={"select"}
        className={"h-full w-full bg-transparent absolute px-4"}
        onClick={() => {
          setShowOptions(true);
        }}
        onBlur={() => {
          setShowOptions(false);
        }}
      />
      <div
        className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        aria-hidden={"true"}
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9954 18.667L19.834 9.33366L8.16732 9.33366L13.9954 18.667Z"
            fill={theme === "light" ? "black" : "white"}
          />
        </svg>
      </div>
      {showOptions && (
        <div className="inset-0" ref={optionRef}>
          <Keyboard
            options={options}
            keyEnter={keyEnter}
            arrowUp={arrowUp}
            arrowDown={arrowDown}
            keyEsc={keyEsc}
            selected={selected}
          />
          <div
            ref={selectRef}
            className={`absolute left-0 top-0 mt-[60px] z-10 font-light text-[22px]
                        text-${theme}-normal bg-${theme}-option min-w-full max-h-[45vh] scrollbar overflow-y-scroll`}
          >
            {options.map((item, index) => (
              <div
                id={getItem(item)}
                key={index}
                className={`w-full h-10 px-6 py-2 ${selectCss(item, index)}`}
                aria-label={categoryI18n[getItem(item)]}
                aria-roledescription={"option"}
                onClick={() => _changeValue(getItem(item))}
              >
                <p
                  onClick={() => _changeValue(getItem(item))}
                  aria-label={categoryI18n[getItem(item)]}
                >
                  {categoryI18n[getItem(item)]}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
