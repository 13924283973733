import axios from "axios";

export default class Api {
  constructor({ url, token }) {
    this.url = url;
    this.token = token;
  }

  async generateToken() {
    const response = await axios.post(
      `${this.url}/api/v1/auth/api-token-auth`,
      {
        token: this.token,
      }
    );
    const { token } = response.data;
    return token;
  }

  async GET(path) {
    const response = await fetch(`${this.url}${path}`);
    return await response.json();
  }

  async POST(path, data) {
    const token = await this.generateToken();
    return await axios.post(`${this.url}${path}`, data, {
      headers: {
        Authorization: token,
      },
    });
  }

  async uploadFile(url, fileFieldId, onUploadProgressFunc) {
    const config = {
      onUploadProgress: onUploadProgressFunc,
    };
    const data = new FormData();
    data.append("file", document.getElementById(fileFieldId).files[0]);
    return await axios.post(url, data, config);
  }
}

export { CreateAC } from "./feedback";
