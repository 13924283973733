import { createSlice } from "@reduxjs/toolkit";

export const element = createSlice({
  name: "ui",
  initialState: {
    lang: "en",
    theme: "rog",
    privacyNotificationDialogId: "privacyNotificationDialog",
    submitDialogId: "submitDialog",
    errorDialogId: "errorDialog",
    isShowErrorDialog: false,
    isShowSubmitDialog: false,
    isShowPrivacyNotificationDialog: false,
    focusNameList: ["email", "first_category", "description"],
    focusIndex: 0,
    isFocus: false,
    enableRootKeyboardEvent: true,
  },
  reducers: {
    init: (state) => {
      state.lang = "en";
      state.theme = "rog";
      state.enableRootKeyboardEvent = true;
      state.isShowErrorDialog = false;
      state.isShowSubmitDialog = false;
      state.isShowPrivacyNotificationDialog = false;
      state.focusNameList = ["email", "first_category", "description"];
      state.focusIndex = 0;
      state.isFocus = false;
    },
    setIsFocus: (state, action) => {
      state.isFocus = action.payload;
    },
    nextFocusIndex: (state) => {
      state.focusIndex = state.focusIndex + 1;
    },
    setFocusIndex: (state, action) => {
      state.focusIndex = action.payload;
    },
    setEnableRootKeyboardEvent: (state, action) => {
      state.enableRootKeyboardEvent = action.payload;
    },
    initFocusNameList: (state) => {
      state.focusNameList = ["email", "first_category", "description"];
    },
    setFocusNameList: (state, action) => {
      state.focusNameList = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setDocument: (state, action) => {
      state.document = action.payload;
    },
    triggerPrivacyNotificationDialog: (state, action) => {
      state.isShowPrivacyNotificationDialog = action.payload;
    },
    triggerSubmitDialog: (state, action) => {
      state.isShowSubmitDialog = action.payload;
    },
    triggerErrorDialog: (state, action) => {
      state.isShowErrorDialog = action.payload;
    },
    triggerDialog: (state, action) => {
      const { id, isOpen } = action.payload;
      switch (id) {
        case state.privacyNotificationDialogId:
          state.isShowPrivacyNotificationDialog = isOpen;
          break;
        case state.submitDialogId:
          state.isShowSubmitDialog = isOpen;
          break;
        case state.errorDialogId:
          state.isShowErrorDialog = isOpen;
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    // builder.addCase('feedback/setAgreePrivacyPolicy', (state, action) => {
    //   state.isShowPrivacyNotificationDialog = false
    // })
  },
});

export default element.reducer;
