import { configureStore } from '@reduxjs/toolkit'
import feedbackReducer from './feedback'
import elementReducer from './element'
import uwpReducer from './uwp'
import { feedback } from './feedback'
import { element } from './element'
import { uwp } from './uwp'

export const feedbackAction = feedback.actions
export const elementAction = element.actions
export const uwpAction = uwp.actions
export default configureStore({
  reducer: {
    feedback: feedbackReducer,
    element: elementReducer,
    uwp: uwpReducer,
  },
})


