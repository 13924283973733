import Api, { CreateAC } from '../../api'
import Uwp from '../uwp'
import IpGeo from '../../api/ip-geo'
import Log from '../log'

export class AppClient {
  static async sendFeedback ({ email, category_id, description }) {
    const api = new Api({
      // eslint-disable-next-line no-undef
      url: config.BACKEND_URL,
    })
    const feedbackInfo = await Uwp.getFeedbackInfo()
    return api.POST(CreateAC, {
      ...{
        ...feedbackInfo,
        email,
        category_id,
        description,
      },
    })
  }
  static async getCountry() {
    const api = new IpGeo(window.config.IPGEO_WORLDWIDE, window.config.IPGEO_CHINA)
    const data = await api.get()
    if (data) {
      Log.debug('get country code', data)
      return data.country_code2
    }
    return ''
  }
}
