export default function TitleIcon ({ width, height }) {
  return <svg width={width} height={height} viewBox="0 0 32 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_20724_13801" style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="12">
      <rect y="0.5" width="32" height="11" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_20724_13801)">
      <path d="M5 0.5H0L11 11.5H16L5 0.5Z" fill="#FF1929"/>
      <path d="M21 0.5H16L27 11.5H32L21 0.5Z" fill="#FF1929"/>
      <path d="M13 0.5H8L19 11.5H24L13 0.5Z" fill="#FF1929"/>
      <path d="M-3 0.5H-8L3 11.5H8L-3 0.5Z" fill="#FF1929"/>
    </g>
  </svg>

}
