export default function Checkbox({
  theme,
  id,
  className,
  label,
  isFocus,
  isEnabled,
  isChecked,
  onClick = () => {},
}) {
  const checkboxItem = () => {
    let css = "";
    const tick = theme === "light" ? "bg-white-tick" : "bg-dark-tick";
    if (isFocus) {
      css += ` border-${theme}-on-focus border-[1.5px]  ${isChecked} `;
      if (isChecked) {
        css += ` ${tick} bg-cover bg-${theme}-input-focus  bg-opacity-80`;
      }
    } else {
      const borderColor = isEnabled
        ? ` border-${theme}-checkbox-enabled `
        : ` border-${theme}-checkbox-disabled `;
      const bgColor = isEnabled
        ? ` bg-${theme}-checkbox-enabled `
        : ` bg-${theme}-checkbox-disabled `;
      css += ` ${
        theme !== "light" && "border-[1.5px]"
      } ${borderColor}  `;
      if (isChecked) {
        css += ` ${tick} bg-cover bg-${theme}-checked `;
      } else {
        css += ` border-[2px] `;
      }
    }
    return css;
  };

  return (
    <>
      <div className={`flex ` + className}>
        <div
          id={id}
          aria-hidden={"true"}
          aria-label={label}
          aria-roledescription={"checkbox"}
          className={`w-7 h-7 mr-3  
            ${checkboxItem()}`}
          onClick={onClick}
        />
        <label
          className={`text ${
            isEnabled ? `text-${theme}-normal` : `text-${theme}-disabled`
          }`}
          form="scales"
          aria-label={label}
          aria-roledescription={"input"}
          aria-hidden={"true"}
          onClick={onClick}
        >
          {label}
        </label>
      </div>
    </>
  );
}
