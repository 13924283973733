import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { elementAction, feedbackAction } from "../../store";
import { Trans } from "react-i18next";
import { Keyboard } from "../control";
import { use } from "i18next";
import { Button } from "../elements";

export default function PrivacyNotificationDialog(props) {
  const { id, t } = props;
  const dispatch = useDispatch();
  const [focusName, setFocusName] = useState("");
  const policyNoticeContentRef = useRef();
  const [focusId, setFocusId] = useState("not_agree");
  const [showBottomAgreeButton, setShowBottomAgreeButton] = useState(true);
  const [showPrivacyPolicyAbortAge, setShowPrivacyPolicyAbortAge] =
    useState(false);
  const theme = useSelector((state) => state.element.theme);
  const [isAgreePrivacy, setIsAgreePrivacy] = useState(false);
  const privacyPolicyAbortAge = useSelector(
    (state) => state.feedback.privacyPolicyAbortAge
  );
  const { setAgreePrivacyPolicy } = feedbackAction;

  useEffect(() => {
    // setFocusOptions(['agree', 'not_agree'])
    // scrollContent(0);
    // setShowBottomAgreeButton(true);
    policyNoticeContentRef.current.focus();
    // focusDisagree()
    document.getElementById(id).addEventListener("cancel", (event) => {
      event.preventDefault();
    });
  });

  useEffect(() => {
    if (privacyPolicyAbortAge > 0) {
      setShowPrivacyPolicyAbortAge(true);
      //
    } else {
      setShowPrivacyPolicyAbortAge(false);
      // setFocusOptions(['agree', 'not_agree'])
    }
  }, [privacyPolicyAbortAge]);

  useEffect(() => {
    if (showBottomAgreeButton) {
      if (privacyPolicyAbortAge === 0) {
        setFocusId("not_agree");
        setIsAgreePrivacy(true);
      } else {
        setFocusId("age");
        setIsAgreePrivacy(false);
      }
    } else {
      setFocusId("content");
      setIsAgreePrivacy(false);
    }
  }, [showBottomAgreeButton]);

  useEffect(() => {
    if (!isAgreePrivacy) {
      dispatch(setAgreePrivacyPolicy(false));
    }
  }, [isAgreePrivacy]);

  const agreePrivacy = () => {
    dispatch(feedbackAction.setPrivacyNoticeAgreedAt());
    dispatch(feedbackAction.setAgreePrivacyPolicy(true));
    dispatch(feedbackAction.checkSubmit());
    dispatch(elementAction.triggerPrivacyNotificationDialog(false));
  };

  const keyEsc = () => {
    dispatch(elementAction.triggerPrivacyNotificationDialog(false));
  };

  const onScroll = () => {
    let isNearBottom = false;
    if (policyNoticeContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        policyNoticeContentRef.current;
      isNearBottom = scrollTop + clientHeight >= scrollHeight - 5;
    }
    setShowBottomAgreeButton(isNearBottom);
    return isNearBottom;
  };

  const clickEnablePrivacy = () => {
    if (showBottomAgreeButton) {
      setIsAgreePrivacy(!isAgreePrivacy);
      setFocusId("not_agree");
    }
  };
  useEffect(() => {
    const listInnerElement = policyNoticeContentRef.current;
    if (listInnerElement) {
      //scroll to top
      listInnerElement.addEventListener("scroll", onScroll);
      scrollContent(0, true);
      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [policyNoticeContentRef]);

  const scrollContent = (direct, replace = false) => {
    if (policyNoticeContentRef.current) {
      if (replace) {
        policyNoticeContentRef.current.scrollTop = direct;
        return;
      }
      policyNoticeContentRef.current.scrollTop += direct;
    }
  };

  const keyboardContentEvent = (scroll) => {
    if (showBottomAgreeButton) {
      if (privacyPolicyAbortAge === 0) {
        setFocusId("not_agree");
      } else {
        setFocusId("age");
      }
    } else {
      scrollContent(scroll);
    }
  };

  const arrowDown = () => {
    switch (focusId) {
      case "content":
        keyboardContentEvent(50);
        break;
      case "age":
        setFocusId("not_agree");
        break;
      case "not_agree":
        console.log("set to agree");
        setFocusId("agree");
        break;
      case "agree":
        setFocusId("not_agree");
        // scrollContent(50);
        break;
      default:
        break;
    }
  };

  const arrowUp = () => {
    switch (focusId) {
      case "content":
        keyboardContentEvent(-50);
        break;
      case "age":
        setFocusId("content");
        break;
      case "not_agree":
        if (privacyPolicyAbortAge === 0) {
          setFocusId("not_agree");
        } else {
          setFocusId("age");
        }
        break;
      case "agree":
        setFocusId("not_agree");
        break;
      default:
        break;
    }
  };
  const arrowLeft = () => {
    switch (focusId) {
      case "age":
        setFocusId("not_agree");
        break;
      case "agree":
        setFocusId("not_agree");
        break;
      case "not_agree":
        setFocusId("agree");
        break;
      default:
        break;
    }
  };
  const arrowRight = () => {
    switch (focusId) {
      case "age":
        setFocusId("agree");
        break;
      case "agree":
        setFocusId("not_agree");
        break;
      case "not_agree":
        setFocusId("agree");
        break;
      default:
        break;
    }
  };

  const keyEnter = () => {
    switch (focusId) {
      case "age":
        clickEnablePrivacy();
        break;
      case "not_agree":
        disagree();
        break;
      case "agree":
        agreePrivacy();
        break;
      default:
    }
  };

  const disagree = () => {
    dispatch(feedbackAction.setAgreePrivacyPolicy(false));
    dispatch(elementAction.triggerDialog({ id, isOpen: false }));
  };

  const selectedItem = (id) => {
    return isSelectedItem(id) ? "border-on-focus" : "";
  };

  const isSelectedItem = (id) => {
    return id === focusId;
  };

  return (
    <dialog id={id} className="bg-transparent font-sans antialiased">
      <Keyboard
        options={[]}
        arrowUp={arrowUp}
        arrowDown={arrowDown}
        arrowLeft={arrowLeft}
        arrowRight={arrowRight}
        keyEnter={keyEnter}
        keyEsc={keyEsc}
        selected={0}
      />
      {/* <div className="fixed right-0 left-0 top-0 bottom-0 bg-black opacity-80 blur-sm	" /> */}
      <div className={`fixed right-0 left-0 top-0 bottom-0 bg-${theme}`} />

      {/* <div className="w-[90vw] h-[70vh] bg-black border-[1px] border-gray-400 relative flex flex-col "> */}
      <div className={`w-full h-[85vh] bg-${theme} relative flex   `}>
        <div className="w-full h-[125px] col-start-1">
          {/* <div className="absolute inset-0 w-full h-[100px] bg-dialog-header" /> */}
          {/* <div className="absolute inset-0 w-full h-[100px]" /> */}
          <div className={`px-6 text-${theme}-normal`}>
            <h1
              className="text-[26px] fon t-semibold "
              aria-label={t("privacyNotice")}
            >
              {t("privacyNotice")}
            </h1>
            {/*Privacy Notice Start */}
            <div className={"relative"}>
              <a />
              <p
                ref={policyNoticeContentRef}
                id={"policyNoticeContent"}
                className="pt-1 text-xl text-left mt-3 font-light h-[50vh] scrollbar overflow-y-scroll whitespace-pre-line z-998"
                aria-label={t("privacyNoticeContent")}
              >
                <a href={"#"} />
                <Trans i18nKey="privacyNoticeContent">
                  <a
                    className="underline"
                    href={t("privacy_notice_link")}
                    target={"new"}
                  />
                </Trans>
              </p>
              <div
                hidden={showBottomAgreeButton}
                className="pointer-events-none	absolute bottom-0 left-0 w-full h-14 bg-gradient-to-t from-black to-transparent"
              ></div>
            </div>
            {/*Privacy Notice End */}
            {showPrivacyPolicyAbortAge && (
              <div className="flex justify-left items-center w-full mt-5">
                <div
                  className={`w-7 h-7 border-[1px] ${
                    showBottomAgreeButton ? "border-white" : "border-normal"
                  } ${
                    isAgreePrivacy
                      ? `bg-${theme}-tick bg-cover ${
                          isSelectedItem("age")
                            ? "bg-btn-hover border-btn-hover"
                            : "bg-white"
                        } `
                      : selectedItem("age")
                  }`}
                  onClick={clickEnablePrivacy}
                  id="privacy_notice_above_age"
                />
                <label
                  className={`pl-3 text ${
                    showBottomAgreeButton ? "text-normal" : "text-disabled"
                  }`}
                  form="scales"
                  onClick={clickEnablePrivacy}
                  aria-label={t("privacy_notice_above_age_text")}
                >
                  {t("privacy_notice_above_age_text", {
                    above_age: privacyPolicyAbortAge,
                  })}
                </label>
              </div>
            )}
          </div>
        </div>

        {/* Bottom Start */}
        {/* <div className="absolute bottom-[-13px] w-full h-[70px] bg-dialog-bottom" /> */}
        {/* Bottom End */}
      </div>
      <div
        className={`fixed left-0 right-0 bottom-0 h-[120px] bg-${theme}-input`}
      />
      <div className="flex flex-row absolute bottom-6 right-6 space-x-4">
        {/* Cancel Button Start */}
        <div
          className={`fixed left-8 bottom-8 justify-start min-w-[150px] max-w-[300px] h-[48px] hover:border-blue-400  z-[999] group`}
        >
          <Button
            id="not_agree"
            theme={theme}
            onClick={disagree}
            isFocus={isSelectedItem}
            isEnabled={isAgreePrivacy}
          >
            {t("not_agree")}
          </Button>
          {/* <button
            id={"not_agree"}
            aria-roledescription={"button"}
            aria-label={t("not_agree")}
            className={`h-full w-full bg-black text-2xl px-4 border-[0.5px] border-gray-400 text-white font-semibold relative
              group-hover:bg-btn-hover group-hover:border-btn-hover
              group-active:bg-btn-click group-active:border-btn-click ${selectedItem(
                "not_agree"
              )}
              `}
            onClick={() => {
              disagree();
            }}
          >
            {t("not_agree")}
            <div className="absolute w-2 h-2 left-0 top-0 border-t-[1.5px] border-l-[1.5px] group-hover:border-btn-hover-light group-active:border-btn-click-light" />
            <div className="absolute w-2 h-2 left-0 bottom-0 border-b-[1.5px] border-l-[1.5px] group-hover:border-btn-hover-light group-active:border-btn-click-light" />
            <div className="absolute w-2 h-2 right-0 bottom-0 border-b-[1.5px] border-r-[1.5px] group-hover:border-btn-hover-light group-active:border-btn-click-light" />
          </button> */}
        </div>
        {/* Cancel Button End */}
        {/* Agree Button Start */}
        <div
          className={`fixed right-8 bottom-8 min-w-[150px] max-w-[300px] h-[48px] z-[999] group`}
        >
          <Button
            id="agree"
            theme={theme}
            onClick={agreePrivacy}
            isFocus={isSelectedItem}
            isEnabled={isAgreePrivacy}
          >
            {t("agree")}
          </Button>
          {/* <button
            id={"agree"}
            aria-roledescription={"button"}
            aria-label={t("agree")}
            disabled={!isAgreePrivacy}
            className={`h-full w-full bg-${theme}-btn-enabled text-2xl px-4 border-[0.5px] border-gray-400  font-semibold relative 
                          ${
                            isAgreePrivacy
                              ? "border-gray-400 text-white hover:border-blue-400 hover:border-2 " +
                                "group-hover:bg-btn-hover group-hover:border-btn-hover " +
                                "focus:bg-btn-hover focus:border-btn-hover text-normal" +
                                "group-active:bg-btn-click group-active:border-btn-click " +
                                selectedItem("agree")
                              : "border-normal text-disabled"
                          }
              `}
            onClick={agreePrivacy}
          >
            {t("agree")}
            <div
              className={`absolute w-2 h-2 left-[-1px] top-[-1px]
              ${
                isAgreePrivacy
                  ? " group-hover:border-btn-hover-light group-active:border-btn-click-light"
                  : "border-gray-500"
              }
               border-t-[1.5px] border-l-[1.5px]`}
            />
            <div
              className={`absolute w-2 h-2 left-[-1px] bottom-[-1px]
              ${
                isAgreePrivacy
                  ? "group-hover:border-btn-hover-light group-active:border-btn-click-light"
                  : "border-gray-500"
              }
               border-b-[1.5px] border-l-[1.5px] `}
            />
            <div
              className={`absolute w-2 h-2 right-[-1px] bottom-[-1px]
              ${
                isAgreePrivacy
                  ? "group-hover:border-btn-hover-light group-active:border-btn-click-light"
                  : "border-gray-500"
              }
               border-b-[1.5px] border-r-[1.5px]`}
            />
          </button> */}
        </div>
        {/* Agree Button End */}
      </div>
    </dialog>
  );
}
