import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { register } from './serviceWorkerRegistration'
import { Provider } from 'react-redux'
import store from './store'
import { element } from './store/element'
import { feedback } from './store/feedback'
import Uwp from './utils/uwp'
import Log from './utils/log'
import { AppClient } from './utils/tool/client'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// eslint-disable-next-line no-undef
window.config = config

window.$react = {
  store: store,
  dispatch: store.dispatch,
  action: { ...feedback.actions, ...element.actions },
  tools: { client: AppClient },
}
window.$uwp = Uwp
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register()

const uwp = await Uwp.initBridge()
if (uwp) {
  Uwp.intervalSync(store.dispatch)
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data && event.data.type === 'refresh') {
      if (event.data.url === window.location.href) {
        window.location.reload()
      }
    }
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
